import { useEffect } from 'react';
import LinkedInTag from 'react-linkedin-insight';
import { useRouter } from 'next/router';
import { LINKEDIN_PARTNER_ID } from 'utils/analytics/linkedinPixel';

const LinkedInPixel = () => {
  const router = useRouter();

  useEffect(() => {
    if (!LINKEDIN_PARTNER_ID) return;

    LinkedInTag.init(LINKEDIN_PARTNER_ID);

    function onRouteChange() {
      LinkedInTag.init(LINKEDIN_PARTNER_ID);
    }

    router.events.on('routeChangeComplete', onRouteChange);
    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, []);
  return null;
};

export default LinkedInPixel;
