import { cvAcceptableDocumentTypes } from '../validation-data';
import { IValidateDocumentInCV } from './cv-document.interfaces';

export const validateDocumentInCV = (file: File): IValidateDocumentInCV => {
  let error = '';
  if (!(file.type && cvAcceptableDocumentTypes.includes(file.type))) {
    error = 'incorrectFileType';
  }

  if (file.size > 20000000) {
    error = 'fileTooLarge';
  }

  return { isValid: !error, error };
};

export default validateDocumentInCV;
