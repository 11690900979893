import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const PersonalInfoSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    full_name: Yup.string()
      .min(3, t(`${prefix}tooShort`))
      .max(50, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
    city: Yup.string()
      .nullable()
      .required(t(`${prefix}required`)),
    email: Yup.string()
      .email(t(`${prefix}email`))
      .required(t(`${prefix}required`)),
    phone_number: Yup.string()
      .min(10, t(`${prefix}tooShort`))
      .max(14, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
  });
