import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
  notAcceptedDates,
} from '../validation-data';

export const WorkExperienceSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    position: Yup.string()
      .max(255, t(`${prefix}tooLong`))
      .notOneOf(['Default'], t(`${prefix}notValid`))
      .required(t(`${prefix}required`)),
    company_name: Yup.string()
      .max(255, t(`${prefix}tooLong`))
      .notOneOf(['Default'], t(`${prefix}notValid`))
      .required(t(`${prefix}required`)),
    company_website_url: Yup.string()
      .max(255, t(`${prefix}tooLong`))
      .nullable()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        t(`${prefix}url`),
      ),
    description: Yup.string()
      .nullable()
      .max(2000, t(`${prefix}tooLong`)),
    started_at: Yup.string()
      .nullable()
      .notOneOf(notAcceptedDates, t(`${prefix}notValid`))
      .required(t(`${prefix}required`)),
    ended_at: Yup.string().when('currentlyHere', {
      is: (val) => val === true || val === undefined,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .notOneOf(notAcceptedDates, t(`${prefix}notValid`))
        .required(t(`${prefix}required`)),
    }),
    currentlyHere: Yup.bool().nullable(),
    hide_work_experience: Yup.bool().nullable(),
  });
