import { ValidationError } from 'yup';
import { EducationSchema } from '../education-schema';
import { LanguagesSchema } from '../languages-schema';
import { WorkExperienceSchema } from '../work-experience-schema';
import { IValidateCV } from './cv.interfaces';

export const validateCV = async (cv: any): Promise<IValidateCV> => {
  if (
    !cv.personal_information?.full_name ||
    !cv.personal_information?.city ||
    !cv.personal_information?.phone_number ||
    cv.skills?.length < 3 ||
    cv.educations?.length < 1
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw new ValidationError();
  }

  const promises = [];

  const WorkExperienceValidation = async (experience: any) =>
    WorkExperienceSchema().validate(experience);
  cv.work_experiences.forEach((experience: any) =>
    promises.push(WorkExperienceValidation(experience)),
  );

  const EducationValidation = async (education: any) =>
    EducationSchema().validate(education);
  cv.educations.forEach((education: any) =>
    promises.push(EducationValidation(education)),
  );

  promises.push(LanguagesSchema().validate(cv.language_skills));

  await Promise.all(promises);

  return { isValid: true };
};

export const validateCVSync = (cv: any): IValidateCV => {
  let isValid = true;
  const invalidFields: any[] = [];

  if (
    !cv.personal_information?.full_name ||
    !cv.personal_information?.city ||
    !cv.personal_information?.phone_number ||
    !LanguagesSchema().isValidSync(cv.language_skills) ||
    cv.educations?.length < 1
  ) {
    isValid = false;
    invalidFields.push('other');
  }

  if (cv.skills?.length < 3) {
    isValid = false;
    invalidFields.push('skills');
  }

  cv.work_experiences.forEach((experience: any) => {
    if (!WorkExperienceSchema().isValidSync(experience)) {
      if (!invalidFields.includes('experience')) {
        invalidFields.push('experience');
      }
      isValid = false;
    }
  });

  cv.educations.forEach((education: any) => {
    if (!EducationSchema().isValidSync(education)) {
      if (!invalidFields.includes('education')) {
        invalidFields.push('education');
      }

      isValid = false;
    }
  });

  return { isValid, invalidFields };
};
