import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const LanguageSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    language: Yup.object({})
      .nullable()
      .required(t(`${prefix}required`)),
    proficiency: Yup.object()
      .nullable()
      .required(t(`${prefix}required`)),
  });
