import { ILanguage } from './languages.interfaces';

export const LANGUAGES: ILanguage[] = [
  {
    id: 'af',
    name: 'Afrikaans',
    native_name: 'Afrikaans',
  },
  {
    id: 'am',
    name: 'Amharic',
    native_name: 'አማርኛ',
  },
  {
    id: 'ar',
    name: 'Arabic',
    native_name: 'العربية',
  },
  {
    id: 'ay',
    name: 'Aymara',
    native_name: 'aymar aru',
  },
  {
    id: 'az',
    name: 'Azerbaijani',
    native_name: 'azərbaycan dili',
  },
  {
    id: 'be',
    name: 'Belarusian',
    native_name: 'Беларуская',
  },
  {
    id: 'bg',
    name: 'Bulgarian',
    native_name: 'български език',
  },
  {
    id: 'bi',
    name: 'Bislama',
    native_name: 'Bislama',
  },
  {
    id: 'bn',
    name: 'Bengali',
    native_name: 'বাংলা',
  },
  {
    id: 'bs',
    name: 'Bosnian',
    native_name: 'bosanski jezik',
  },
  {
    id: 'ca',
    name: 'Catalan',
    native_name: 'Català',
  },
  {
    id: 'cs',
    name: 'Czech',
    native_name: 'česky',
  },
  {
    id: 'da',
    name: 'Danish',
    native_name: 'dansk',
  },
  {
    id: 'de',
    name: 'German',
    native_name: 'Deutsch',
  },
  {
    id: 'dv',
    name: 'Divehi',
    native_name: 'ދިވެހި',
  },
  {
    id: 'el',
    name: 'Greek',
    native_name: 'Ελληνικά',
  },
  {
    id: 'en',
    name: 'English',
    native_name: 'English',
  },
  {
    id: 'es',
    name: 'Spanish',
    native_name: 'español',
  },
  {
    id: 'et',
    name: 'Estonian',
    native_name: 'eesti',
  },
  {
    id: 'fa',
    name: 'Persian',
    native_name: 'فارسی',
  },
  {
    id: 'fi',
    name: 'Finnish',
    native_name: 'suomi',
  },
  {
    id: 'fr',
    name: 'French',
    native_name: 'français',
  },
  {
    id: 'ga',
    name: 'Irish',
    native_name: 'Gaeilge',
  },
  {
    id: 'he',
    name: 'Hebrew ',
    native_name: 'עברית',
  },
  {
    id: 'hi',
    name: 'Hindi',
    native_name: 'हिन्दी',
  },
  {
    id: 'hr',
    name: 'Croatian',
    native_name: 'hrvatski',
  },
  {
    id: 'ht',
    name: 'Haitian',
    native_name: 'Kreyòl ayisyen',
  },
  {
    id: 'hu',
    name: 'Hungarian',
    native_name: 'Magyar',
  },
  {
    id: 'hy',
    name: 'Armenian',
    native_name: 'Հայերեն',
  },
  {
    id: 'id',
    name: 'Indonesian',
    native_name: 'Bahasa Indonesia',
  },
  {
    id: 'is',
    name: 'Icelandic',
    native_name: 'Íslenska',
  },
  {
    id: 'it',
    name: 'Italian',
    native_name: 'Italiano',
  },
  {
    id: 'ja',
    name: 'Japanese',
    native_name: '日本語 ',
  },
  {
    id: 'ka',
    name: 'Georgian',
    native_name: 'ქართული',
  },
  {
    id: 'kk',
    name: 'Kazakh',
    native_name: 'Қазақ тілі',
  },
  {
    id: 'km',
    name: 'Khmer',
    native_name: 'ភាសាខ្មែរ',
  },
  {
    id: 'ko',
    name: 'Korean',
    native_name: '한국어 ',
  },
  {
    id: 'ku',
    name: 'Kurdish',
    native_name: 'Kurdî',
  },
  {
    id: 'ky',
    name: 'Kirghiz',
    native_name: 'кыргыз тили',
  },
  {
    id: 'la',
    name: 'Latin',
    native_name: 'latine',
  },
  {
    id: 'lb',
    name: 'Luxembourgish',
    native_name: 'Lëtzebuergesch',
  },
  {
    id: 'lo',
    name: 'Lao',
    native_name: 'ພາສາລາວ',
  },
  {
    id: 'lt',
    name: 'Lithuanian',
    native_name: 'lietuvių kalba',
  },
  {
    id: 'lv',
    name: 'Latvian',
    native_name: 'latviešu valoda',
  },
  {
    id: 'mg',
    name: 'Malagasy',
    native_name: 'Malagasy fiteny',
  },
  {
    id: 'mk',
    name: 'Macedonian',
    native_name: 'македонски јазик',
  },
  {
    id: 'mn',
    name: 'Mongolian',
    native_name: 'монгол',
  },
  {
    id: 'ms',
    name: 'Malay',
    native_name: 'bahasa Melayu',
  },
  {
    id: 'mt',
    name: 'Maltese',
    native_name: 'Malti',
  },
  {
    id: 'my',
    name: 'Burmese',
    native_name: 'ဗမာစာ',
  },
  {
    id: 'ne',
    name: 'Nepali',
    native_name: 'नेपाली',
  },
  {
    id: 'nl',
    name: 'Dutch',
    native_name: 'Nederlands',
  },
  {
    id: 'no',
    name: 'Norwegian',
    native_name: 'Norsk',
  },
  {
    id: 'ny',
    name: 'Chichewa',
    native_name: 'chiCheŵa',
  },
  {
    id: 'pl',
    name: 'Polish',
    native_name: 'polski',
  },
  {
    id: 'ps',
    name: 'Pashto',
    native_name: 'پښتو',
  },
  {
    id: 'pt',
    name: 'Portuguese',
    native_name: 'Português',
  },
  {
    id: 'qu',
    name: 'Quechua',
    native_name: 'Runa Simi',
  },
  {
    id: 'rm',
    name: 'Romansh',
    native_name: 'rumantsch grischun',
  },
  {
    id: 'rn',
    name: 'Kirundi',
    native_name: 'kiRundi',
  },
  {
    id: 'ro',
    name: 'Romanian',
    native_name: 'română',
  },
  {
    id: 'ru',
    name: 'Russian',
    native_name: 'русский язык',
  },
  {
    id: 'rw',
    name: 'Kinyarwanda',
    native_name: 'Ikinyarwanda',
  },
  {
    id: 'si',
    name: 'Sinhala',
    native_name: 'සිංහල',
  },
  {
    id: 'sk',
    name: 'Slovak',
    native_name: 'slovenčina',
  },
  {
    id: 'sl',
    name: 'Slovene',
    native_name: 'slovenščina',
  },
  {
    id: 'sm',
    name: 'Samoan',
    native_name: 'gagana faa Samoa',
  },
  {
    id: 'so',
    name: 'Somali',
    native_name: 'Soomaaliga',
  },
  {
    id: 'sq',
    name: 'Albanian',
    native_name: 'Shqip',
  },
  {
    id: 'sr',
    name: 'Serbian',
    native_name: 'српски језик',
  },
  {
    id: 'st',
    name: 'Southern Sotho',
    native_name: 'Sesotho',
  },
  {
    id: 'sv',
    name: 'Swedish',
    native_name: 'svenska',
  },
  {
    id: 'sw',
    name: 'Swahili',
    native_name: 'Kiswahili',
  },
  {
    id: 'ta',
    name: 'Tamil',
    native_name: 'தமிழ்',
  },
  {
    id: 'tg',
    name: 'Tajik',
    native_name: 'тоҷикӣ',
  },
  {
    id: 'th',
    name: 'Thai',
    native_name: 'ไทย',
  },
  {
    id: 'ti',
    name: 'Tigrinya',
    native_name: 'ትግርኛ',
  },
  {
    id: 'tk',
    name: 'Turkmen',
    native_name: 'Türkmen',
  },
  {
    id: 'tn',
    name: 'Tswana',
    native_name: 'Setswana',
  },
  {
    id: 'tr',
    name: 'Turkish',
    native_name: 'Türkçe',
  },
  {
    id: 'uk',
    name: 'Ukrainian',
    native_name: 'українська',
  },
  {
    id: 'ur',
    name: 'Urdu',
    native_name: 'اردو',
  },
  {
    id: 'uz',
    name: 'Uzbek',
    native_name: 'zbek',
  },
  {
    id: 'vi',
    name: 'Vietnamese',
    native_name: 'Tiếng Việt',
  },
  {
    id: 'zh',
    name: 'Chinese',
    native_name: '中文 ',
  },
];
