import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DislikedJobsReducerState } from 'store/types';

const initialState: DislikedJobsReducerState = {
  jobs: [],
  loading: false,
  externalJobsIds: [],
  jobsIds: [],
};

const dislikedJobsSlice = createSlice({
  name: 'dislikedJobs',
  initialState,
  reducers: {
    setDislikedJobs: (
      state,
      action: PayloadAction<{
        jobs: DislikedJobsReducerState['jobs'];
      }>,
    ) => ({
      ...state,
      jobs: [...state.jobs, ...action.payload.jobs],
      loading: false,
    }),
    resetDislikedJobs: () => initialState,
    setDislikedJobsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setExternalDislikedJobsIds: (
      state,
      action: PayloadAction<DislikedJobsReducerState['externalJobsIds']>,
    ) => ({
      ...state,
      externalJobsIds: action.payload,
    }),
    signOut: () => initialState,
  },
});

export const {
  setDislikedJobs,
  resetDislikedJobs,
  setDislikedJobsLoading,
  signOut,
  setExternalDislikedJobsIds,
} = dislikedJobsSlice.actions;
export default dislikedJobsSlice.reducer;
