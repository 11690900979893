import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const CreateAccountSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    terms: Yup.boolean().oneOf([true], t(`${prefix}terms`)),
    consent: Yup.boolean().oneOf([true], t(`${prefix}consent`)),
    full_name: Yup.string()
      .min(3, t(`${prefix}tooShort`))
      .max(50, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
    email: Yup.string()
      .email(t(`${prefix}email`))
      .required(t(`${prefix}required`)),
    password: Yup.string()
      .min(6, t(`${prefix}tooShort`))
      .max(100, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
  });
