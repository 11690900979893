import isWindow from 'utils/isWindow';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const G_ADS_ID = process.env.NEXT_PUBLIC_G_ADS_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const gtagJobDimensionsEvent = (jobId, jobLocationId) => {
  if (GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      custom_map: {
        dimension1: 'job_id',
        dimension2: 'job_locid',
        dimension3: 'google_business_vertical'
      },
    });

    window.gtag('event', "job_dimensions", {
      job_id: jobId,
      job_locid: jobLocationId,
      google_business_vertical: 'jobs'
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event: action,
    event_category: category,
    event_label: label,
    value: value,
  });
};

// https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
export const gtagWebVitalsEvent = ({ id, name, label, value }) => {
  if (isWindow() && GA_TRACKING_ID) {
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
};
