import isWindow from 'utils/isWindow';

export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pixelPageView = () => {
  window.fbq('track', 'PageView');
};

export const event = (name, options = {}) => {
  window.fbq('track', name, options);
};

export const fbEvent = (pixelEvent, { category, label, value }) => {
  if (isWindow() && FACEBOOK_PIXEL_ID) {
    event(pixelEvent, {
      content_category: category,
      content_name: label,
      value,
    });
  }
};
