import { createSlice } from '@reduxjs/toolkit';
import { UiReducerStateType } from 'store/types';

const initialState: UiReducerStateType = {
  showCreateAccountModal: false,
  showLoginModal: false,
  showBuildCVModal: false,
  showForgotPasswordModal: false,
  showDeepLinksModal: true,
  showNotPermitted: true,
  openJobTypeFilter: false,
  openFiltersOnMobile: false,
  isShowMobileStoresBanner: false,
};

const uiSlice = createSlice({
  name: 'uiReducer',
  initialState,
  reducers: {
    openCreateAccountModal: (state) => ({
      ...state,
      showCreateAccountModal: true,
    }),
    closeCreateAccountModal: (state) => ({
      ...state,
      showCreateAccountModal: false,
    }),
    openLoginModal: (state) => ({
      ...state,
      showLoginModal: true,
    }),
    closeLoginModal: (state) => ({
      ...state,
      showLoginModal: false,
    }),
    openBuildCVModal: (state) => ({
      ...state,
      showBuildCVModal: true,
    }),
    closeBuildCVModal: (state) => ({
      ...state,
      showBuildCVModal: false,
    }),
    openForgotPasswordModal: (state) => ({
      ...state,
      showForgotPasswordModal: true,
    }),
    closeForgotPasswordModal: (state) => ({
      ...state,
      showForgotPasswordModal: false,
    }),
    closeDeepLinksModal: (state) => ({
      ...state,
      showDeepLinksModal: false,
    }),
    showNotPermitted: (state) => ({
      ...state,
      showNotPermitted: true,
    }),
    hideNotPermitted: (state) => ({
      ...state,
      showNotPermitted: false,
    }),
    openJobTypeFilter: (state) => ({
      ...state,
      openJobTypeFilter: true,
    }),
    hideJobTypeFilter: (state) => ({
      ...state,
      openJobTypeFilter: false,
    }),
    openFiltersOnMobile: (state) => ({
      ...state,
      openFiltersOnMobile: true,
    }),
    closeFiltersOnMobile: (state) => ({
      ...state,
      openFiltersOnMobile: false,
    }),
    showMobileStoresBanner: (state) => ({
      ...state,
      isShowMobileStoresBanner: true,
    }),
    closeMobileStoresBanner: (state) => ({
      ...state,
      isShowMobileStoresBanner: false,
    }),
  },
});

export const {
  openCreateAccountModal,
  closeCreateAccountModal,
  openLoginModal,
  closeLoginModal,
  openBuildCVModal,
  closeBuildCVModal,
  openForgotPasswordModal,
  closeForgotPasswordModal,
  closeDeepLinksModal,
  hideNotPermitted,
  showNotPermitted,
  openFiltersOnMobile,
  closeFiltersOnMobile,
  openJobTypeFilter,
  hideJobTypeFilter,
  showMobileStoresBanner,
  closeMobileStoresBanner,
} = uiSlice.actions;

export default uiSlice.reducer;
