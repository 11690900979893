import { acceptableFileTypes } from '../validation-data';
import { IValidateMessageAttachment } from './message-attachment.interfaces';

export const validateMessageAttachment = (
  file: File,
): IValidateMessageAttachment => {
  let error = '';
  if (!(file.type && acceptableFileTypes.includes(file.type))) {
    error = 'incorrectFileType';
  }

  if (file.size > 65000000) {
    error = 'fileTooLarge';
  }

  return { isValid: !error, error };
};
