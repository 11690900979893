import { decode as atob } from 'base-64';

interface PayloadJwt extends Record<string, any> {
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  aud: string;
  jti: string;
  typ: string;
  azp: string;
  scope: string;
}

export const parseJWT = (token: string): PayloadJwt | null => {
  try {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error });
    return null;
  }
};
