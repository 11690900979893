import { createTheme } from '@mui/material';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

const candidatesTheme = {
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 960,
      lg: 1416,
      xl: 1920,
    },
  },
  palette,
  shadows,
  typography,
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
};

// Create a theme instance.
const theme = createTheme(candidatesTheme as any);

export default theme;
