import Cookies from 'js-cookie';
import { axios } from '../axios';
import { ISetAuthTokenData } from './set-auth-token-data.interfaces';

export const setAuthTokenData = (tokenData: ISetAuthTokenData): void => {
  if (tokenData) {
    // Apply to every request
    Cookies.set('nploy_token', tokenData.access_token);
    Cookies.set('nploy_refresh_token', tokenData?.refresh_token);
    axios.defaults.headers.Authorization = `Bearer ${tokenData.access_token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common.Cookie;
  }
};
