import { configureStore } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { dislikesJobsApi } from 'store/services';
import reducers from './reducers';

let store;

const initialState = {};

function initStore(preloadedState = initialState) {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(dislikesJobsApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
    reducer: reducers,
  });
}

export const initializeStore = (preloadedState: any) => {
  let newStore = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    newStore = initStore({
      ...preloadedState,
      ...store.getState(),
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return newStore;
  // Create the store once in the client
  if (!store) store = newStore;

  return newStore;
};

export function useStore(initial: any) {
  return useMemo(() => initializeStore(initial), [initial]);
}

export type RootState = ReturnType<typeof reducers>;
export type AppStore = ReturnType<typeof initStore>;
export type AppDispatch = AppStore['dispatch'];
