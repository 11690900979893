import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FACEBOOK_PIXEL_ID, pixelPageView } from 'utils/analytics/fbPixel';

export const FacebookPixel = () => {
  const [loaded, setLoaded] = useState(false);
  const { pathname } = useRouter();

  useEffect(() => {
    if (loaded) {
      pixelPageView();
    }
  }, [pathname, loaded]);

  return (
    <div>
      <Script
        id="fb-pixel"
        src="/scripts/pixel.js"
        strategy="afterInteractive"
        onLoad={() => setLoaded(true)}
        data-pixel-id={FACEBOOK_PIXEL_ID}
      />
    </div>
  );
};
