import moment from 'moment';

export const sortByDateProperty = (
  arrayToSort: any[],
  propertyName: string,
): any[] => {
  if (!arrayToSort) return [];
  return arrayToSort.sort(
    (a, b) =>
      moment(b[propertyName]).valueOf() - moment(a[propertyName]).valueOf(),
  );
};

export default sortByDateProperty;
