import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LikedJobsReducerState } from 'store/types';

const initialState: LikedJobsReducerState = {
  jobs: [],
  loading: false,
  externalJobsIds: [],
  jobsIds: [],
};

const likedJobsSlice = createSlice({
  name: 'likedJobs',
  initialState,
  reducers: {
    setLikedJobs: (
      state,
      action: PayloadAction<{
        jobs: LikedJobsReducerState['jobs'];
      }>,
    ) => ({
      ...state,
      jobs: [...state.jobs, ...action.payload.jobs],
      loading: false,
    }),
    resetLikedJobs: () => initialState,
    setLikedJobsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setExternalLikedJobsIds: (
      state,
      action: PayloadAction<LikedJobsReducerState['externalJobsIds']>,
    ) => ({
      ...state,
      externalJobsIds: action.payload,
    }),
    signOut: () => initialState,
  },
});

export const {
  setLikedJobs,
  resetLikedJobs,
  setLikedJobsLoading,
  signOut,
  setExternalLikedJobsIds,
} = likedJobsSlice.actions;

export default likedJobsSlice.reducer;
