import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICandidateJobDto } from '@nploy/ui-infrastructure';
import { generateFilteredJobs } from 'utils/helpers/jobs';
import { JobsReducerState } from 'store/types';
import { SIGN_OUT } from '../actions/types';

const initialState: JobsReducerState = {
  pageIndex: 0,
  externalJobsPageIndex: 0,
  jobs: [],
  externalJobs: [],
  filteredJobs: [],
  hasNextPage: true,
  likedIds: [],
  externalLikedIds: [],
  dislikedIds: [],
  externalDislikedIds: [],
  without: [],
  externalWithout: [],
  loading: false,
  jobDeepLink: '',
  hasExternalNextJobsPage: true,
};

const jobsSlice = createSlice({
  name: 'jobsReducer',
  initialState,
  reducers: {
    setJobs: (
      state,
      action: PayloadAction<{
        jobs: ICandidateJobDto[];
        page: number;
        hasNextPage: boolean;
      }>,
    ) => {
      const newJobs = [...state.jobs, ...action.payload.jobs];
      return {
        ...state,
        pageIndex: action.payload.page,
        jobs: newJobs,
        filteredJobs: generateFilteredJobs(
          newJobs,
          state.likedIds,
          state.dislikedIds,
          state.externalLikedIds,
          state.externalDislikedIds,
        ),
        hasNextPage: action.payload.hasNextPage,
        loading: false,
      };
    },
    setJobsWithReset: (
      state,
      action: PayloadAction<{
        jobs: ICandidateJobDto[];
        page: number;
        hasNextPage: boolean;
      }>,
    ) => ({
      ...state,
      pageIndex: action.payload.page,
      jobs: action.payload.jobs,
      filteredJobs: generateFilteredJobs(
        action.payload.jobs,
        state.likedIds,
        state.dislikedIds,
        state.externalLikedIds,
        state.externalDislikedIds,
      ),
      hasNextPage: action.payload.hasNextPage,
      loading: false,
    }),
    resetJobs: () => initialState,
    setLikedIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      likedIds: action.payload,
      filteredJobs: generateFilteredJobs(
        state.filteredJobs,
        action.payload,
        null,
      ),
    }),
    setDislikedIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      dislikedIds: action.payload,
      filteredJobs: generateFilteredJobs(
        state.filteredJobs,
        null,
        action.payload,
      ),
    }),
    setExternalDislikedIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      externalDislikedIds: action.payload,
      filteredJobs: generateFilteredJobs(
        state.filteredJobs,
        null,
        null,
        null,
        action.payload,
      ),
    }),
    setLikedId: (state, action: PayloadAction<number>) => ({
      ...state,
      filteredJobs: state.filteredJobs.filter(({ id, isExternal = false }) => {
        if (!isExternal) {
          return id !== action.payload;
        }
        return true;
      }),
      likedIds: [...state.likedIds, action.payload],
    }),
    setExternalLikedId: (state, action: PayloadAction<number>) => ({
      ...state,
      filteredJobs: state.filteredJobs.filter(({ id, isExternal = false }) => {
        if (isExternal) {
          return id !== action.payload;
        }
        return true;
      }),
      externalLikedIds: [...state.externalLikedIds, action.payload],
    }),
    setDislikedId: (state, action: PayloadAction<number>) => ({
      ...state,
      dislikedIds: [...state.dislikedIds, action.payload],
      filteredJobs: state.filteredJobs.filter(({ id, isExternal = false }) => {
        if (!isExternal) {
          return id !== action.payload;
        }
        return true;
      }),
    }),
    setExternalDislikedId: (state, action: PayloadAction<number>) => ({
      ...state,
      externalDislikedIds: [...state.externalDislikedIds, action.payload],
      filteredJobs: state.filteredJobs.filter(({ id, isExternal = false }) => {
        if (isExternal) {
          return id !== action.payload;
        }
        return true;
      }),
    }),
    setWithoutIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      without: action.payload,
    }),
    setExternalWithoutIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      externalWithout: action.payload,
    }),
    setJobsLoading: (state) => ({
      ...state,
      loading: true,
    }),
    setJobsLoaded: (state) => ({
      ...state,
      loading: false,
    }),
    setJobDeepLink: (state, action: PayloadAction<string>) => ({
      ...state,
      jobDeepLink: action.payload,
    }),
    setHasExternalJobsPage: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasExternalNextJobsPage: action.payload,
    }),
    setNextExternalJobsPage: (state, action: PayloadAction<number>) => ({
      ...state,
      externalJobsPageIndex: action.payload,
    }),
    setExternalJobs: (
      state,
      action: PayloadAction<{
        externalJobs: JobsReducerState['jobs'];
        page: number;
        hasExternalNextJobsPage: boolean;
      }>,
    ) => ({
      ...state,
      jobs: [...state.jobs, ...action.payload.externalJobs],
      filteredJobs: generateFilteredJobs(
        [...state.jobs, ...action.payload.externalJobs],
        state.likedIds,
        state.dislikedIds,
        state.externalLikedIds,
        state.externalDislikedIds,
      ),
      externalJobsPageIndex: action.payload.page,
      hasExternalNextJobsPage: action.payload.hasExternalNextJobsPage,
      loading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(SIGN_OUT, () => initialState);
  },
});

export const {
  setDislikedId,
  resetJobs,
  setDislikedIds,
  setJobDeepLink,
  setJobs,
  setJobsLoaded,
  setJobsLoading,
  setLikedId,
  setLikedIds,
  setWithoutIds,
  setExternalJobs,
  setExternalDislikedId,
  setExternalLikedId,
  setExternalWithoutIds,
  setExternalDislikedIds,
  setJobsWithReset,
} = jobsSlice.actions;

export default jobsSlice.reducer;
