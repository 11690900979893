import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const ForgotPasswordSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    email: Yup.string()
      .email(t(`${prefix}email`))
      .required(t(`${prefix}required`)),
  });
