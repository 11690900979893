import { Translate } from 'next-translate';
import { ICandidateJobDto } from '@nploy/ui-infrastructure';
import moment from 'moment';

export const generateFilteredJobs = (
  jobs: ICandidateJobDto[],
  likedIds: number[] = [],
  dislikedIds: number[] = [],
  externalLikedIds: number[] = [],
  externalDislikedIds: number[] = [],
) =>
  jobs.filter(({ id, isExternal }) => {
    if (isExternal) {
      return (
        !externalLikedIds?.includes(id) && !externalDislikedIds?.includes(id)
      );
    }
    return !likedIds?.includes(id) && !dislikedIds?.includes(id);
  });

export const getPostedAgoTime = (publishedDate: string, t: Translate) => {
  try {
    const days = moment().diff(publishedDate, 'days');

    if (typeof days !== 'number' || days < 0) return '';

    const xDaysAgo = t('dayAgo', { count: days });

    return `${t('job.jobPosted')} ${xDaysAgo}`;
  } catch (error) {
    return '';
  }
};

export const getTopJobRolesText = (jobs, topJobsNumber = 3) => {
  if (!jobs || !Array.isArray(jobs)) return '';

  const topJobs = jobs.slice(0, topJobsNumber);

  const topJobRolesText = topJobs.reduce((accumulator, job) => {
    if (job?.job_role?.name) return `${accumulator}${job?.job_role?.name}, `;
    return accumulator;
  }, '');

  return topJobRolesText.substring(0, topJobRolesText.length - 2);
};
