import React, { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { SWRConfig } from 'swr';
import { handleError } from 'utils/swr/helpers/errorHandling';

export const SWRConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const handleSWRError = (error) => {
    console.log(error);
    if (error.status !== 403 && error.status !== 404) {
      handleError(dispatch, error);
    }
  };

  return <SWRConfig value={{ onError: handleSWRError }}>{children}</SWRConfig>;
};
