import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackState } from 'store/types';

const initialState: FeedbackState = {
  open: false,
  type: '',
  message: '',
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    openFeedback: (
      state,
      action: PayloadAction<Pick<FeedbackState, 'type' | 'message'>>,
    ) => ({
      ...state,
      type: action.payload.type,
      message: action.payload.message,
      open: true,
    }),
    closeFeedback: (state) => ({
      ...state,
      open: false,
    }),
  },
});

export const { openFeedback, closeFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
