export const uniqueByProperty = <T>(arr: T[], property: string): T[] => {
  const keys = new Set();
  const result = [];

  arr.forEach((item) => {
    if (!keys.has(item[property])) {
      keys.add(item[property]);
      result.push(item);
    }
  });

  return result;
};

export const filterUniqueByNestedProperty = <T>(
  array: T[],
  property: string,
): T[] => {
  const uniqueValues: T[] = [];

  array.forEach((item) => {
    const nestedValue = property
      .split('.')
      .reduce((value, prop) => value?.[prop], item);

    if (nestedValue) {
      const isDuplicate = uniqueValues.some(
        (uniqueItem) =>
          property
            .split('.')
            .reduce((value, prop) => value?.[prop], uniqueItem) === nestedValue,
      );

      if (!isDuplicate) {
        uniqueValues.push(item);
      }
    }
  });

  return uniqueValues;
};
