import {
  ILanguageLevel,
  ILanguageProficiency,
} from './language-levels.interfaces';

export const LANGUAGE_LEVELS_SPEAKING: ILanguageLevel[] = [
  {
    id: 1,
    name: 'Basic',
    weight: 0,
  },
  {
    id: 2,
    name: 'Conversational',
    weight: 0,
  },
  {
    id: 3,
    name: 'Fluent',
    weight: 0,
  },
];

export const LANGUAGE_LEVELS_WRITING: ILanguageLevel[] = [
  {
    id: 1,
    name: 'Basic',
    weight: 0,
  },
  {
    id: 2,
    name: 'Advanced',
    weight: 0,
  },
  {
    id: 3,
    name: 'Proficient',
    weight: 0,
  },
];

export const LANGUAGE_PROFICIENCY: ILanguageProficiency[] = [
  {
    id: 1,
    name: 'Basic',
    label: 'Basic',
    value: 'Basic',
    weight: 0,
    key: 'Basic',
    code: 'A',
  },
  {
    id: 6,
    name: 'Lower Intermediate',
    label: 'Lower Intermediate',
    value: 'Lower Intermediate',
    weight: 0,
    key: 'Lower Intermediate',
    code: 'B1',
  },
  {
    id: 2,
    name: 'Upper Intermediate',
    label: 'Upper Intermediate',
    value: 'Upper Intermediate',
    weight: 0,
    key: 'Upper Intermediate',
    code: 'B2',
  },
  {
    id: 3,
    name: 'Advanced',
    label: 'Advanced',
    value: 'Advanced',
    weight: 0,
    key: 'Advanced',
    code: 'C1',
  },
  {
    id: 4,
    name: 'Proficient',
    label: 'Proficient',
    value: 'Proficient',
    weight: 0,
    key: 'Proficient',
    code: 'C2',
  },
  {
    id: 5,
    name: 'Native',
    label: 'Native',
    value: 'Native',
    weight: 0,
    key: 'Native',
    code: '',
  },
];
