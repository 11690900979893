import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const ChangePasswordSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.object({
    current_password: Yup.string()
      .min(6, t(`${prefix}tooShort`))
      .max(100, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
    new_password: Yup.string()
      .min(6, t(`${prefix}tooShort`))
      .max(100, t(`${prefix}tooLong`))
      .required(t(`${prefix}required`)),
  });
