export const cvAcceptableDocumentTypes: string[] = [
  '.pdf',
  '.zip',
  '.rar',
  '.txt',
  '.bin',
  '.doc',
  '.docx',
  '.rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-excel',
  'text/plain',
  'application/pdf',
  'application/x-zip-compressed',
  'application/octet-stream',
  'application/vnd.rar',
];
