const prefix = 'http://';

export const transformLink = (string: string): string => {
  let newString = string;

  if (!/^https?:\/\//i.test(newString)) {
    newString = prefix + newString;
  }

  return string;
};

export default transformLink;
