export const sortByProperty = <T>(
  arrayToSort: T[],
  propertyName: string,
): T[] => {
  if (!arrayToSort) return [];
  const compare = (a: T, b: T) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  };

  return arrayToSort.sort(compare);
};

export default sortByProperty;
