import { cvAcceptableFileTypes } from '../validation-data';
import { IValidateCVFile } from './cv-file.interfaces';

export const validateCVFile = (
  file: File,
  docType: string,
): IValidateCVFile => {
  let error = '';
  if (
    !(docType && cvAcceptableFileTypes.includes(docType)) &&
    !(file.type && cvAcceptableFileTypes.includes(file.type))
  ) {
    error = 'incorrectFileType';
  }

  return { isValid: !error, error };
};
