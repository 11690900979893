import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuggestingReducerState } from 'store/types';

const initialState: SuggestingReducerState = {
  suggestedSkills: null,
  suggestedCategories: null,
};

const suggestionReducer = createSlice({
  initialState,
  name: 'suggestionReducer',
  reducers: {
    setSuggestedSkills: (
      state,
      action: PayloadAction<SuggestingReducerState['suggestedSkills']>,
    ) => ({
      ...state,
      suggestedSkills: action.payload,
    }),
    setSuggestedCategories: (
      state,
      action: PayloadAction<SuggestingReducerState['suggestedCategories']>,
    ) => ({
      ...state,
      suggestedCategories: action.payload,
    }),
  },
});

export const { setSuggestedSkills, setSuggestedCategories } =
  suggestionReducer.actions;
export default suggestionReducer.reducer;
