import { openFeedback } from 'store/reducers/feedbackReducer';
import { AppDispatch } from 'store/store';

export const handleError = (error: any, dispatch: AppDispatch) => {
  dispatch(
    openFeedback({
      type: 'error',
      message: error.response?.data?.errors || error.message,
    }),
  );
};
