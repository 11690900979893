export const cvAcceptableFileTypes: string[] = [
  '.doc',
  '.docx',
  '.rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/pdf',
];
