export const sendQuickMetricAnalytics = ({ name, value }) => {
  if (process.env.NEXT_PUBLIC_QUIKMETRICS_API_KEY) {
    const url = `https://qckm.io?m=${name}&v=${Math.round(
      name === "CLS" ? value * 1000 : value
    )}&k=${process.env.NEXT_PUBLIC_QUIKMETRICS_API_KEY}`;

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url);
    } else {
      fetch(url, { method: "POST", keepalive: true });
    }
  }
};
