import { AxiosResponse } from 'axios';
import { axios } from '../axios';

export const readBase64Async = (file: File): Promise<string | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      }
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export const uploadBase64 = async (
  file: File,
  endpoint: string,
): Promise<AxiosResponse<any>> => {
  const fileBase64 = await readBase64Async(file);
  const base64 = (fileBase64 as string).split(',').pop();
  const params = { store: true, document: base64 };
  return axios.post(endpoint, params);
};
