import localFont from 'next/font/local';

export const nployFont = localFont({
  src: [
    {
      path: '../public/fonts/Eina03-Regular.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/Eina03-RegularItalic.otf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../public/fonts/Eina03-SemiBold.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/Eina03-Bold.otf',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const typography = {
  fontFamily: `${nployFont.style.fontFamily}, sans-serif`,

  // hero
  h1: {
    fontSize: '1.625rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
  },
  // mega
  h2: {
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  // title
  h3: {
    fontSize: '1.25rem',
    fontWeight: 600,
    '@media (min-width:600px)': {
      fontSize: '1.125rem',
    },
  },
  // subheader
  h4: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  // h5, h6, subtitle1, subtitle2 - not in designs
  // body leading
  body1: {
    fontSize: '1rem',
    fontWeight: 700,
    '@media (min-width:600px)': {
      fontSize: '0.875rem',
    },
  },
  // body
  body2: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  // button
  button: {
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'none',
  },
  // caption
  caption: {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
};

export default typography;
