import * as Yup from 'yup';
import {
  defaultTranslateFunc,
  defaultTranslationPrefix,
} from '../validation-data';

export const LanguagesSchema = (
  t = defaultTranslateFunc,
  prefix = defaultTranslationPrefix,
) =>
  Yup.array()
    .of(
      Yup.object({
        language: Yup.object().required(t(`${prefix}required`)),
        proficiency: Yup.object().required(t(`${prefix}required`)),
      }),
    )
    .min(1, t('languagesMin'))
    .test({
      name: 'has-native',
      test: (languages: unknown) => {
        const nativeLang = ((languages as Record<string, any>[]) || []).find(
          ({ proficiency: { id: proficiencyId } }) => proficiencyId === 5,
        );
        return !!nativeLang;
      },
      message: t('nativeLangRequired'),
    });
