import { combineReducers } from '@reduxjs/toolkit';
import { dislikesJobsApi } from 'store/services';
import cvReducer from './cvReducer';
import dislikedJobsReducer from './dislikedJobsReducer';
import feedbackReducer from './feedbackReducer';
import jobsReducer from './jobsReducer';
import likedJobsReducer from './likedJobsReducer';
import { onboardingReducer } from './onboardingReducer';
import suggestionReducer from './suggestionReducer';
import uiReducer from './uiReducer';

export default combineReducers({
  feedback: feedbackReducer,
  jobs: jobsReducer,
  ui: uiReducer,
  likedJobs: likedJobsReducer,
  dislikedJobs: dislikedJobsReducer,
  cv: cvReducer,
  onboardingReducer,
  suggestionReducer,
  [dislikesJobsApi.reducerPath]: dislikesJobsApi.reducer,
});
