import { Shadows } from '@mui/material';

export const shadows: Shadows = [
  'none',
  '0px 2px 4px 0px rgba(50,50,93,0.1)', // 1
  '0px 3px 6px -1px rgba(50,50,93,0.2)', // 2
  '0px 4px 6px 0px rgba(50,50,93,0.11)', // 3
  '0px 7px 4px -1px rgba(50,50,93,0.1),0px 3px 6px 0px rgba(0,0,0,0.08)', // 4
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 8px 16px 0px rgba(8,16,64,0.08)', // 6
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 6px 12px -2px rgba(50,50,93,0.25),0px 3px 7px -3px rgba(0,0,0,0.3)', // 8
  '0px 8px 14px -2px rgba(50,50,93,0.25),0px 5px 9px -2px rgba(0,0,0,0.15)', // 9
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 14px 24px 0px rgba(5,55,50,0.16),0px 1px 24px -7px rgba(5,55,50,0.08)', // 12
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 13px 27px -5px rgba(50,50,93,0.25),0px 8px 16px -8px rgba(0,0,0,0.3),0px -6px 16px -6px rgba(0,0,0,0.25)', // 16
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 30px 60px -12px rgba(50,50,93,0.25),0px 18px 36px -18px rgba(0,0,0,0.3),0px -12px 36px -8px rgba(0,0,0,0.25)', // 24
];

export default shadows;
