import { BaseQueryFn, createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';

export const DISLIKES_JOBS = 'dislikesJobs';

const customBaseQuery: BaseQueryFn = async (args) => {
  try {
    const { data } = await axios.request(args);
    return { data };
  } catch (e) {
    console.log(e);
  }
};

export const dislikesJobsApi = createApi({
  reducerPath: DISLIKES_JOBS,
  tagTypes: [DISLIKES_JOBS],
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    getDislikesJobsIds: build.query<any, void>({
      query: () => ({
        url: 'jobs/scraper-job/disliked?per_page=10',
        method: 'get',
      }),
    }),
    getLikedJobsIds: build.query<any, void>({
      query: () => ({
        url: 'jobs/scraper-job/liked?per_page=10',
        method: 'get',
      }),
    }),
    resetDislikesJobs: build.mutation<any, void>({
      query: () => ({
        url: 'jobs/scraper-job/reset_dislike',
        method: 'post',
      }),
    }),
    likeJob: build.mutation<void, { id: number; type: 1 | 2 }>({
      query: ({ id, type }) => ({
        url: `jobs/scraper-job/scraper-job/${id}/liked/${type}`,
        method: 'post',
      }),
    }),
  }),
});
